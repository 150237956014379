$rpvue-Avatar--small-size:            1.125rem;
$rpvue-Avatar--small-Icon-size:       .75rem;
$rpvue-Avatar--small-Name-font:       $label-small;
$rpvue-Avatar--medium-size:           1.5rem;
$rpvue-Avatar--medium-Icon-size:      1.125rem;
$rpvue-Avatar--medium-Name-font:      $label-large;
$rpvue-Avatar--large-size:            2.25rem;
$rpvue-Avatar--large-Icon-size:       1.5rem;
$rpvue-Avatar--large-Name-font:       $title-medium;



.rpvue-Avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  flex: none;

  color: $neutral-surface;
  background: $color-primary;
  border-radius: 50%;

  .rpvue-Avatar-Name {
    text-transform: uppercase;
  }

  &.rpvue-Avatar--small {
    width: $rpvue-Avatar--small-size;
    height: $rpvue-Avatar--small-size;

    .rpvue-Avatar-Icon {
      font-size: $rpvue-Avatar--small-Icon-size;
    }

    .rpvue-Avatar-Name {
      @include font-settings($rpvue-Avatar--small-Name-font);
    }
  }

  &.rpvue-Avatar--medium {
    width: $rpvue-Avatar--medium-size;
    height: $rpvue-Avatar--medium-size;

    .rpvue-Avatar-Icon {
      font-size: $rpvue-Avatar--medium-Icon-size;
    }

    .rpvue-Avatar-Name {
      @include font-settings($rpvue-Avatar--medium-Name-font);
    }
  }

  &.rpvue-Avatar--large {
    width: $rpvue-Avatar--large-size;
    height: $rpvue-Avatar--large-size;

    .rpvue-Avatar-Icon {
      font-size: $rpvue-Avatar--large-Icon-size;
    }

    .rpvue-Avatar-Name {
      @include font-settings($rpvue-Avatar--large-Name-font);
    }
  }
}
