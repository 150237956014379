:root {
  --rpvue-ActivityStreamEmailDraft-background:                var(--rpvue-colorNeutralSurface);
  --rpvue-ActivityStreamEmailDraft-border:                    var(--rpvue-borderWidth) solid var(--rpvue-colorBorder-alt);
  --rpvue-ActivityStreamEmailDraft-borderRadius:              var(--rpvue-borderRadiusLg);
  --rpvue-ActivityStreamEmailDraft-boxShadow:                 var(--rpvue-elevate-200-bottom);

  --rpvue-ActivityStreamEmailDraft-body-height:               25rem;
  --rpvue-ActivityStreamEmailDraft-body-isExpanded-height:    calc(100vh - #{$header-height} - var(--rpvue-ActivityStream-draft-boundariesOffset) * 2);
}

.rpvue-ActivityStreamEmailDraft {
  display: flex;
  flex-direction: column;

  overflow: hidden;

  height: var(--rpvue-ActivityStreamEmailDraft-body-height);

  background: var(--rpvue-ActivityStreamEmailDraft-background);
  border: var(--rpvue-ActivityStreamEmailDraft-border);
  border-radius: var(--rpvue-ActivityStreamEmailDraft-borderRadius);
  box-shadow: var(--rpvue-ActivityStreamEmailDraft-boxShadow);

  &.is-expanded {
    height: var(--rpvue-ActivityStreamEmailDraft-body-isExpanded-height);
  }
}

.rpvue-ActivityStreamEmailDraft-body {
  display: flex;
  flex: 1;
}
