$rpvue-QuadroContainer-gap:           .625rem;
$rpvue-QuadroContainer-padding:       .25rem 0;
$rpvue-QuadroContainer-Row-gap:       .375rem;
$rpvue-QuadroContainer-Slot-gap:      .375rem;



.rpvue-QuadroContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: $rpvue-QuadroContainer-gap;
  padding: $rpvue-QuadroContainer-padding;
}

.rpvue-QuadroContainer-Row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: $rpvue-QuadroContainer-Row-gap;

  &.rpvue-QuadroContainer-Row--top {
    height: $label-large-line-height;
    color: $neutral-surface-bg;
  }

  &.rpvue-QuadroContainer-Row--bottom {
    height: $label-medium-line-height;
    color: $neutral-surface-var-bg;
  }
}

.rpvue-QuadroContainer-Slot {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  gap: $rpvue-QuadroContainer-Slot-gap;
  min-width: 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @include font-settings($label-medium);

  > rp-icon {
    font-size: $icon-size-sm;
    color: $neutral-surface-var-bg;
  }

  &.rpvue-QuadroContainer-Slot--large-font {
    @include font-settings($label-large);

    > rp-icon {
      font-size: $icon-size-md;
    }
  }

  &.rpvue-QuadroContainer-Slot--left {
    text-align: left;
    justify-content: flex-start;
  }

  &.rpvue-QuadroContainer-Slot--right {
    text-align: right;
    justify-content: flex-end;
  }
}

.rpvue-QuadroContainer-Text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
