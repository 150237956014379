$rpvue-Badge-gap:               .25rem;
$rpvue-Badge-height:            1rem;
$rpvue-Badge-padding:           0 .5rem;
$rpvue-Badge-borderRadius:      .25rem;
$rpvue-Badge-Icon-size:         .75rem;
$rpvue-Badge-Text-font:         $label-small;



.rpvue-Badge {
  display: inline-flex;
  align-items: center;

  gap: $rpvue-Badge-gap;
  height: $rpvue-Badge-height;
  padding: $rpvue-Badge-padding;

  border-radius: $rpvue-Badge-borderRadius;
}

.rpvue-Badge-Icon {
  font-size: $rpvue-Badge-Icon-size;
}

.rpvue-Badge-Text {
  white-space: nowrap;

  @include font-settings($rpvue-Badge-Text-font);
}

@each $color, $value in $status-colors {
  .rpvue-Badge--#{$color} {
    color: $neutral-surface;
    background-color: $value;
  }
}

.rpvue-Badge--cancelled {
  color: $color-error;
  background-color: $color-error-var;

  .rpvue-Badge-Text {
    text-decoration: line-through;
  }
}
