:root {
  --rpvue-ActivityStreamEmailDraftSubject-padding:                    var(--rpvue-spacer-3) 0;
  --rpvue-ActivityStreamEmailDraftSubject-borderBottom:               var(--rpvue-borderWidth) solid var(--rpvue-colorBorder-alt);

  --rpvue-ActivityStreamEmailDraftSubject-label-paddingRight:         var(--rpvue-spacer-6);

  --rpvue-ActivityStreamEmailDraftSubject-input-placeholder-color:    var(--rpvue-colorpaletteBlack-500);
}

.rpvue-ActivityStreamEmailDraftSubject {
  display: flex;
  align-items: center;

  padding: var(--rpvue-ActivityStreamEmailDraftSubject-padding);
  border-bottom: var(--rpvue-ActivityStreamEmailDraftSubject-borderBottom);
}

.rpvue-ActivityStreamEmailDraftSubject-label {
  display: inline-flex;

  padding-right: var(--rpvue-ActivityStreamEmailDraftSubject-label-paddingRight);
}

.rpvue-ActivityStreamEmailDraftSubject-input {
  display: flex;
  flex: 1;

  input {
    flex: 1;
    padding: 0;

    border: none;
    outline: none;

    &::placeholder {
      color: var(--rpvue-ActivityStreamEmailDraftSubject-input-placeholder-color);
    }
  }
}
