$rpvue-UserMenuActiveProfile-width:                   18.5rem;
$rpvue-UserMenuActiveProfile-gap:                     map-get($spacers, 2);
// Consider updating _header.scss when changing the horizontal padding
$rpvue-UserMenuActiveProfile-padding:                 map-get($spacers, 3);
$rpvue-UserMenuActiveProfile-onHover-backgroundColor: $palette-purple-100;
$rpvue-UserMenuActiveProfile-borderRadius:            $border-radius-small;
$rpvue-UserMenuActiveProfile-info-width:              12rem;
$rpvue-UserMenuActiveProfile-developerLogo-maxWidth:  3.5rem;
$rpvue-UserMenuActiveProfile-developerLogo-maxHeight: 2.125rem;



.rpvue-UserMenuActiveProfile {
  display: flex;
  align-items: center;
  width: $rpvue-UserMenuActiveProfile-width;
  padding: $rpvue-UserMenuActiveProfile-padding;
  border-radius: $rpvue-UserMenuActiveProfile-borderRadius;
  gap: $rpvue-UserMenuActiveProfile-gap;

  &:hover {
    background-color: $rpvue-UserMenuActiveProfile-onHover-backgroundColor;
  }
}

.rpvue-UserMenuActiveProfile-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  max-width: $rpvue-UserMenuActiveProfile-info-width;
}

.rpvue-UserMenuActiveProfile-developerLogo {
  display: flex;
  max-width: $rpvue-UserMenuActiveProfile-developerLogo-maxWidth;
  max-height: $rpvue-UserMenuActiveProfile-developerLogo-maxHeight;
  // If the logo is vertical and doesn't fill all the space, margin-left is used to move it to the left side
  margin-left: auto;
}