$rpvue-UserMenuProfiles-padding:   map-get($spacers, 2);
$rpvue-UserMenuProfiles-gap:       map-get($spacers, 1);



.rpvue-UserMenuProfiles {
  display: flex;
  flex-direction: column;
  gap: $rpvue-UserMenuProfiles-gap;
  padding: $rpvue-UserMenuProfiles-padding;
}