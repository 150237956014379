$rpvue-UserMenuProfilesHeader-padding:       map-get($spacers, 2);
$rpvue-UserMenuProfilesHeader-text-maxWidth: 16.625rem;
$rpvue-UserMenuProfilesHeader-logo-width:    .75rem;
$rpvue-UserMenuProfilesHeader-logo-height:   .75rem;



.rpvue-UserMenuProfilesHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $rpvue-UserMenuProfilesHeader-padding;
}

.rpvue-UserMenuProfilesHeader-text {
  display: flex;
  max-width: $rpvue-UserMenuProfilesHeader-text-maxWidth;
}

.rpvue-UserMenuProfilesHeader-logo {
  display: flex;
  width: $rpvue-UserMenuProfilesHeader-logo-width;
  height: $rpvue-UserMenuProfilesHeader-logo-height;
}