:root {
  --rpvue-ActivityStreamEmailDraftRecipientsList-gap:   var(--rpvue-spacer-3);
}

.rpvue-ActivityStreamEmailDraftRecipientsList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  gap: var(--rpvue-ActivityStreamEmailDraftRecipientsList-gap);
}
