:root {
  --rpvue-ActivityStreamPerson-gap: var(--rpvue-spacer-2);
}

.rpvue-ActivityStreamPerson {
  display: flex;
  align-items: center;

  gap: var(--rpvue-ActivityStreamPerson-gap);
}
