$rpvue-UserMenu-maxWidth:  18.5rem;



.rpvue-UserMenu {
  max-width: $rpvue-UserMenu-maxWidth;
}

.rpvue-u-UserMenu-button {
  border: none;
  background: none;
}