:root {
  --rpvue-ActivityStreamEmailDraftBody-editor-placeholder-color: var(--rpvue-colorpaletteBlack-500);
}

.rpvue-ActivityStreamEmailDraftBody {
  display: flex;
  flex: 1;
}

.rpvue-ActivityStreamEmailDraftBody-editor {
  display: flex;
  flex: 1;

  textarea {
    flex: 1;
    padding: 0;

    border: none;
    outline: none;
    resize: none;

    &::placeholder {
      color: var(--rpvue-ActivityStreamEmailDraftBody-editor-placeholder-color);
    }
  }
}
