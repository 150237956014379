// ############################################################################
// Axiforma
// ############################################################################

@font-face {
  font-family: "Axiforma";
  font-weight: 400;
  font-style: normal;
  src: url("../../fonts/Axiforma-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma";
  font-weight: 400;
  font-style: italic;
  src: url("../../fonts/Axiforma-RegularItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma";
  font-weight: 500;
  font-style: normal;
  src: url("../../fonts/Axiforma-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma";
  font-weight: 500;
  font-style: italic;
  src: url("../../fonts/Axiforma-MediumItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma";
  font-weight: 600;
  font-style: normal;
  src: url("../../fonts/Axiforma-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma";
  font-weight: 600;
  font-style: italic;
  src: url("../../fonts/Axiforma-SemiBoldItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma";
  font-weight: 700;
  font-style: normal;
  src: url("../../fonts/Axiforma-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Axiforma";
  font-weight: 700;
  font-style: italic;
  src: url("../../fonts/Axiforma-BoldItalic.woff2") format("woff2");
}

// ############################################################################
// Proxima Nova
// ############################################################################

@font-face {
  font-family: "Proxima Nova";
  font-weight: 400;
  font-style: normal;
  src: url("../../fonts/ProximaNova-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 500;
  font-style: normal;
  src: url("../../fonts/ProximaNova-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 600;
  font-style: normal;
  src: url("../../fonts/ProximaNova-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 700;
  font-style: normal;
  src: url("../../fonts/ProximaNova-Bold.woff2") format("woff2");
}

// ############################################################################
// MaterialSymbolsRounded
// ############################################################################

@font-face {
  font-family: "MaterialSymbolsRounded";
  font-weight: 400;
  font-style: normal;
  src: url("../../fonts/MaterialSymbolsRounded.woff2") format("woff2");
}
