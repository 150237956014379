:root {
  --rpvue-ActivityStreamEmailAddresses-padding: var(--rpvue-spacer-6);
  --rpvue-ActivityStreamEmailAddresses-gap: var(--rpvue-spacer-4);
  --rpvue-ActivityStreamEmailAddresses-container-gap: var(--rpvue-spacer-2);
  --rpvue-ActivityStreamEmailAddresses-row-gap: var(--rpvue-spacer-3);
}

.rpvue-ActivityStreamEmailAddresses {
  display: flex;
  align-items: center;

  padding: var(--rpvue-ActivityStreamEmailAddresses-padding);
  gap: var(--rpvue-ActivityStreamEmailAddresses-gap);

  border-bottom: var(--rpvue-borderWidth) solid var(--rpvue-colorBorder);
}

.rpvue-ActivityStreamEmailAddresses-container {
  display: flex;
  flex-direction: column;

  gap: var(--rpvue-ActivityStreamEmailAddresses-container-gap);
}

.rpvue-ActivityStreamEmailAddresses-row {
  display: flex;
  align-items: center;

  gap: var(--rpvue-ActivityStreamEmailAddresses-row-gap);
}
