:root {
  --rpvue-ActivityStreamListItems-gap:                    var(--rpvue-spacer-7);

  --rpvue-ActivityStreamListItems-line-top:               3rem;
  --rpvue-ActivityStreamListItems-line-lastItem-top:      1.5rem;
  --rpvue-ActivityStreamListItems-line-left:              calc(1rem - 1px);
  --rpvue-ActivityStreamListItems-line-borderWidth:       2px;
  --rpvue-ActivityStreamListItems-line-borderColor:       var(--rpvue-colorpalettePurple-200);
}

.rpvue-ActivityStreamListItems {
  position: relative;

  display: flex;
  flex-direction: column;

  gap: var(--rpvue-ActivityStreamListItems-gap);
}

.rpvue-ActivityStreamListItems::before {
  content: '';

  position: absolute;
  z-index: 1;

  top: var(--rpvue-ActivityStreamListItems-line-top);
  left: var(--rpvue-ActivityStreamListItems-line-left);
  bottom: 0;

  border-right: var(--rpvue-ActivityStreamListItems-line-borderWidth) dashed var(--rpvue-ActivityStreamListItems-line-borderColor);
}

.rpvue-ActivityStreamListItems-item {
  position: relative;
  z-index: 2;
}

.rpvue-ActivityStreamListItems-item:last-child::before {
  content: '';

  position: absolute;
  z-index: 1;

  top: var(--rpvue-ActivityStreamListItems-line-lastItem-top);
  left: var(--rpvue-ActivityStreamListItems-line-left);
  bottom: 0;

  width: var(--rpvue-ActivityStreamListItems-line-borderWidth);
  background: var(--rpvue-ActivityStream-list-background);
}
