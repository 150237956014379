:root {
  --rpvue-ActivityStreamEmailAttachments-gap: var(--rpvue-spacer-4);
}

.rpvue-ActivityStreamEmailAttachments {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  gap: var(--rpvue-ActivityStreamEmailAttachments-gap);
}
