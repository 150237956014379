$rpvue-ActivityStreamListItem-gap:                      1rem;
$rpvue-ActivityStreamListItem-indicator-size:           2rem;
$rpvue-ActivityStreamListItem-indicator-margin:         .25rem 0 0;
$rpvue-ActivityStreamListItem-indicator-color:          $neutral-surface-var-bg;
$rpvue-ActivityStreamListItem-indicator-background:     $neutral-surface-var;
$rpvue-ActivityStreamListItem-indicator-border:         $border-width solid $border-color;
$rpvue-ActivityStreamListItem-indicatorIcon-size:       $icon-size-md;
$rpvue-ActivityStreamListItem-indicator--dot-size:      1rem;
$rpvue-ActivityStreamListItem-indicator--dot-margin:    .875rem .5rem 0;



.rpvue-ActivityStreamListItem {
  display: flex;
  align-items: flex-start;

  gap: $rpvue-ActivityStreamListItem-gap;
}

.rpvue-ActivityStreamListItem-indicator {
  display: flex;
  align-items: center;
  justify-content: center;

  flex: none;
  z-index: 2;

  width: $rpvue-ActivityStreamListItem-indicator-size;
  height: $rpvue-ActivityStreamListItem-indicator-size;
  margin: $rpvue-ActivityStreamListItem-indicator-margin;

  color: $rpvue-ActivityStreamListItem-indicator-color;
  background: $rpvue-ActivityStreamListItem-indicator-background;
  border: $rpvue-ActivityStreamListItem-indicator-border;
  border-radius: divide($rpvue-ActivityStreamListItem-indicator-size, 2);
}

.rpvue-ActivityStreamListItem-indicator--dot {
  width: $rpvue-ActivityStreamListItem-indicator--dot-size;
  height: $rpvue-ActivityStreamListItem-indicator--dot-size;
  margin: $rpvue-ActivityStreamListItem-indicator--dot-margin;

  border-radius: divide($rpvue-ActivityStreamListItem-indicator--dot-size, 2);
}

.rpvue-ActivityStreamListItem-indicatorIcon {
  font-size: $rpvue-ActivityStreamListItem-indicatorIcon-size;
}

.rpvue-ActivityStreamListItem-content {
  flex: 1 1 0;
  min-width: 0;
}
