:root {
  --rpvue-ActivityStreamEmailDraftAttachments-gap: var(--rpvue-spacer-4);
}

.rpvue-ActivityStreamEmailDraftAttachments {
  display: flex;
  align-items: center;

  gap: var(--rpvue-ActivityStreamEmailDraftAttachments-gap);
}
