:root {
  --rpvue-ActivityStreamEmailDraftFormatting-padding:               var(--rpvue-spacer-6);
  --rpvue-ActivityStreamEmailDraftFormatting-gap:                   var(--rpvue-spacer-4);
  --rpvue-ActivityStreamEmailDraftFormatting-background:            var(--rpvue-colorNeutralSurface-alt);
  --rpvue-ActivityStreamEmailDraftFormatting-borderBottom:          var(--rpvue-borderWidth) solid var(--rpvue-colorBorder-alt);

  --rpvue-ActivityStreamEmailDraftFormatting-divider-height:        1.5rem;
  --rpvue-ActivityStreamEmailDraftFormatting-divider-borderRight:   var(--rpvue-borderWidth) solid var(--rpvue-colorBorder);
}

.rpvue-ActivityStreamEmailDraftFormatting {
  display: flex;
  align-items: center;

  padding: var(--rpvue-ActivityStreamEmailDraftFormatting-padding);
  gap: var(--rpvue-ActivityStreamEmailDraftFormatting-gap);

  background: var(--rpvue-ActivityStreamEmailDraftFormatting-background);
  border-bottom: var(--rpvue-ActivityStreamEmailDraftFormatting-borderBottom);
}

.rpvue-ActivityStreamEmailDraftFormatting-item {
  display: inline-flex;
}

.rpvue-ActivityStreamEmailDraftFormatting-divider {
  height: var(--rpvue-ActivityStreamEmailDraftFormatting-divider-height);
  border-right: var(--rpvue-ActivityStreamEmailDraftFormatting-divider-borderRight);
}
