.access-change-form {
  width: 100%;
  max-width: 26rem;

  margin: auto;
  padding: 2rem 0;

  .logo-container {
    display: flex;
    justify-content: center;

    .logo {
      max-width: 10rem;
      margin-bottom: 2rem;
    }
  }
}
