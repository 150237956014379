:root {
  --rpvue-ActivityStreamList-padding:                 var(--rpvue-spacer-9) var(--rpvue-spacer-7);

  --rpvue-ActivityStreamList-tabs-paddingBottom:      var(--rpvue-spacer-9);
}

.rpvue-ActivityStreamList {
  display: flex;
  flex-direction: column;

  padding: var(--rpvue-ActivityStreamList-padding);
}

.rpvue-ActivityStreamList-tabs {
  padding-bottom: var(--rpvue-ActivityStreamList-tabs-paddingBottom);
}

.rpvue-ActivityStreamList-message {
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 0 0 auto;
}

.rpvue-ActivityStreamList-message.is-empty {
  flex: 1 0 auto;
}
