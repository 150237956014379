:root {
  --rpvue-ActivityStream-border:                  var(--rpvue-borderWidth) solid var(--rpvue-colorBorder);
  --rpvue-ActivityStream-borderRadius:            var(--rpvue-borderRadiusLg);

  --rpvue-ActivityStream-header-background:       var(--rpvue-colorpalettePurple-100);
  --rpvue-ActivityStream-header-borderBottom:     var(--rpvue-borderWidth) solid var(--rpvue-colorBorder-alt);

  --rpvue-ActivityStream-list-background:         var(--rpvue-colorNeutralSurface);

  --rpvue-ActivityStream-draft-boundariesOffset:  2rem;
  --rpvue-ActivityStream-draft-right:             var(--rpvue-ActivityStream-draft-boundariesOffset);
  --rpvue-ActivityStream-draft-bottom:            var(--rpvue-ActivityStream-draft-boundariesOffset);
  --rpvue-ActivityStream-draft-zIndex:            2;
  --rpvue-ActivityStream-draft-width:             60rem;
  --rpvue-ActivityStream-draft-maxWidth:          calc(100vw - #{$vue-sidebar-width} - var(--rpvue-ActivityStream-draft-boundariesOffset) * 2);
}

.rpvue-ActivityStreamContainer {
  display: flex;
  flex-direction: column;
}

.rpvue-ActivityStream {
  display: flex;
  flex-direction: column;
  overflow: clip;

  border: var(--rpvue-ActivityStream-border);
  border-radius: var(--rpvue-ActivityStream-borderRadius);
}

.rpvue-ActivityStream-header {
  background: var(--rpvue-ActivityStream-header-background);
  border-bottom: var(--rpvue-ActivityStream-header-borderBottom);
}

.rpvue-ActivityStream-list {
  display: flex;
  flex-direction: column;

  background: var(--rpvue-ActivityStream-list-background);
}

.rpvue-ActivityStream-draft {
  position: fixed;
  right: var(--rpvue-ActivityStream-draft-right);
  bottom: var(--rpvue-ActivityStream-draft-bottom);
  z-index: var(--rpvue-ActivityStream-draft-zIndex);

  width: var(--rpvue-ActivityStream-draft-width);
  max-width: var(--rpvue-ActivityStream-draft-maxWidth);
}
