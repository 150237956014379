:root {
  --rpvue-ActivityStreamEmailDraftHeaderControls-gap:                   var(--rpvue-spacer-4);

  --rpvue-ActivityStreamEmailDraftHeaderControls-divider-height:        1.5rem;
  --rpvue-ActivityStreamEmailDraftHeaderControls-divider-borderRight:   var(--rpvue-borderWidth) solid var(--rpvue-colorBorder);
}

.rpvue-ActivityStreamEmailDraftHeaderControls {
  display: flex;
  align-items: center;

  gap: var(--rpvue-ActivityStreamEmailDraftHeaderControls-gap);
}

.rpvue-ActivityStreamEmailDraftHeaderControls-divider {
  height: var(--rpvue-ActivityStreamEmailDraftHeaderControls-divider-height);
  border-right: var(--rpvue-ActivityStreamEmailDraftHeaderControls-divider-borderRight);
}
