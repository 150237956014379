:root {
  --rpvue-ActivityStreamEmailDraftRecipient-padding:        var(--rpvue-spacer-2) var(--rpvue-spacer-6);
  --rpvue-ActivityStreamEmailDraftRecipient-gap:            var(--rpvue-spacer-3);
  --rpvue-ActivityStreamEmailDraftRecipient-border:         var(--rpvue-borderWidth) solid var(--rpvue-colorpaletteBlack-400);
  --rpvue-ActivityStreamEmailDraftRecipient-borderRadius:   50rem;

  --rpvue-ActivityStreamEmailDraftRecipient-name-color:     var(--rpvue-colorpaletteBlack-500);
}

.rpvue-ActivityStreamEmailDraftRecipient {
  display: flex;
  align-items: center;

  padding: var(--rpvue-ActivityStreamEmailDraftRecipient-padding);
  gap: var(--rpvue-ActivityStreamEmailDraftRecipient-gap);

  border: var(--rpvue-ActivityStreamEmailDraftRecipient-border);
  border-radius: var(--rpvue-ActivityStreamEmailDraftRecipient-borderRadius);
}

.rpvue-ActivityStreamEmailDraftRecipient-avatar {
  display: inline-flex;
}

.rpvue-ActivityStreamEmailDraftRecipient-name {
  display: inline-flex;

  color: var(--rpvue-ActivityStreamEmailDraftRecipient-name-color);
}
