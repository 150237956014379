$rpvue-ActivityStreamEventSection-marginRight:      .25rem;
$rpvue-ActivityStreamEventSection-gap:              .25rem;
$rpvue-ActivityStreamEventSection-icon-size:        $icon-size-sm;
$rpvue-ActivityStreamEventSection-link-color:       $neutral-surface-var-bg;



.rpvue-ActivityStreamEventSection {
  display: inline-flex;

  margin-right: $rpvue-ActivityStreamEventSection-marginRight;
  gap: $rpvue-ActivityStreamEventSection-gap;
}

.rpvue-ActivityStreamEventSection-icon {
  font-size: $rpvue-ActivityStreamEventSection-icon-size;
}

.rpvue-ActivityStreamEventSection-link {
  color: $rpvue-ActivityStreamEventSection-link-color !important;
  text-decoration: underline;
}
