:root {
  --rpvue-ActivityStreamHeader-padding:     var(--rpvue-spacer-7);
}

.rpvue-ActivityStreamHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--rpvue-ActivityStreamHeader-padding);
}
