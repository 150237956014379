.custom-control {
  .custom-control-disabled-tooltip {
    display: none;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  &.disabled {
    .custom-control-disabled-tooltip {
      display: block;
    }
  }
}

.custom-control-input {
  &:focus ~ .custom-control-label::before {
    box-shadow: $custom-control-indicator-focus-box-shadow;
  }
}

.custom-control.custom-control-dangerous {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $color-error;
    border-color: $color-error;
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 $input-btn-focus-width rgba($color-error, .25);
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: lighten($color-error, 25%);
  }
}

.custom-control-label {
  &:not(:empty) {
    padding-left: .5rem;
  }
}

.custom-file {
  font-size: $input-font-size;

  .custom-file-label {
    margin-bottom: 0;
  }
}

.form-group {
  .form-label {
    width: 100%;

    &:empty {
      display: none;
    }
  }

  &.required {
    .form-label::after {
      content: "✱";
      color: $danger;

      font-size: .75em;
      margin-left: .25em;

      line-height: 1;
      vertical-align: top;
    }
  }

  .form-control-container {
    position: relative;
    width: 100%;

    .form-text,
    .invalid-feedback {
      @include font-settings($label-medium);
    }

    .form-text {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      margin-top: 0;
      gap: $form-text-margin-top * 2;

      color: $palette-black-700;

      .form-help {
        &:not(:empty) {
          margin-top: $form-text-margin-top;
        }
      }

      .form-counter {
        white-space: nowrap;

        &:not(:empty) {
          margin-top: $form-text-margin-top;
        }
      }

      a + a {
        padding-left: $form-text-margin-top;
      }
    }

    &.is-invalid {
      .invalid-feedback {
        display: block;
      }

      .form-text {
        display: none;
      }
    }

    .custom-checkbox,
    .custom-switch {
      margin-top: $input-padding-y;
    }
  }

  @include media-breakpoint-up(md) {
    &.form-group-horizontal {
      display: flex;
      flex-wrap: wrap;

      .form-label {
        width: $form-label-width;
        padding-top: add($input-padding-y, $input-border-width);
        padding-bottom: add($input-padding-y, $input-border-width);
        padding-right: $input-btn-padding-x;
        margin-bottom: 0;

        font-size: $input-font-size;
        line-height: $input-line-height;

        &:empty {
          display: inline-block;
        }
      }

      .form-control-container {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
      }
    }
  }
}

.date-range-container {
  display: flex;

  & > .input-group:first-child {
    margin-right: $input-padding-x * 2;
  }
}

.form-control {
  &.is-invalid {
    z-index: 1;
  }
}

textarea.form-control {
  min-height: $input-height;
}

span.form-control {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-thumbnail {
  .form-control {
    height: auto;

    img {
      cursor: pointer;
    }
  }
}

.card {
  overflow: hidden;
  margin-bottom: $spacer;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-header-title {
      line-height: map-get($rp-icon-btn-md, "size");
    }
  }

  .accordion {
    margin: 0 -#{$card-spacer-x}; // interpolation is needed to avoid SCSS engine doing subtract math operation

    &:last-child {
      margin-bottom: -$card-spacer-x;
    }

    & > .card {
      border: none;
      border-top: $card-border-width solid $card-border-color;
      border-radius: 0;
    }
  }
}

.accordion {
  margin-bottom: $spacer;

  & > .card {
    margin-bottom: 0;

    & > .card-header {
      overflow: hidden;
      padding: 0;
    }
  }

  .toggle-expand,
  .toggle-collapse {
    padding: $card-spacer-x;
    border-radius: 0;
  }

  .toggle-expand + .toggle-collapse {
    margin-top: 0;
  }

  .toggle-expand {
    &:not(.collapsed) {
      display: none;
    }
  }

  .toggle-collapse {
    &.collapsed {
      display: none;
    }
  }
}

.input-group {
  .input-group-prepend,
  .input-group-append {
    .btn {
      border-radius: $border-radius;
    }

    .rp-icon-btn {
      border-radius: $border-radius;

      color: $body-color;
    }

    .btn + .rp-icon-btn,
    .rp-icon-btn + .btn,
    .rp-icon-btn + .rp-icon-btn,
    .rp-icon-btn + .input-group-text,
    .input-group-text + .rp-icon-btn {
      margin-left: -$input-border-width;
    }
  }

  .input-group-prepend:empty {
    margin-right: 0;
  }

  .input-group-append:empty {
    margin-left: 0;
  }

  &.highlight {
    transition: transform .3s ease-out;

    &::after {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background: #ffff99;
      opacity: 0;

      transition: opacity .3s ease-out;
    }

    &.in {
      transform: scale(1.02);

      &::after {
        opacity: .5;
      }
    }
  }
}

.input-group-stack {
  .input-group:not(:last-child) {
    margin-bottom: -$border-width;
  }
}

.triple-input-group {
  @include media-breakpoint-up(md) {
    & {
      display: flex;
      flex-wrap: wrap;

      > .input-group {
        flex: 1;

        &:not(:last-child) {
          margin-right: -$border-width;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    & > .input-group:not(:last-child) {
      margin-bottom: -$border-width;
    }
  }
}

.input-group-text {
  .icon {
    font-size: $icon-size-md;
  }

  img {
    height: $input-group-addon-image-height;
  }

  &.language-tag {
    width: 3em;
    justify-content: center;
    text-transform: uppercase;
  }
}

.inline-form {
  margin-bottom: $spacer;

  .inline-form-footer {
    display: flex;
    align-items: center;

    gap: $spacer;
  }
}
