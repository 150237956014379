$rp-table-panel-padding-y:    .5em;
$rp-table-panel-padding-x:    2em;

$rp-table-action-padding:     .25rem;

$rp-table-btn-padding-y:      .5em;
$rp-table-btn-padding-x:      .75em;
$rp-table-btn-margin-x:       .5em;

$rp-table-label-margin:       .5em;
$rp-table-input-width:        5rem;
$rp-table-search-width:       20rem;

$rp-table-cell-padding:       .5em;

.rp-table {
  display: flex;
  flex-direction: column;

  position: relative;
  overflow: hidden;

  margin-bottom: $spacer;

  border: $border-width solid $border-color;
  border-radius: $card-border-radius;

  background: $neutral-surface;

  .main-table-container & {
    margin-bottom: 0;
  }

  .rp-table-loading-panel {
    position: absolute;
    z-index: 2;

    display: flex;
    justify-content: center;

    width: 100%;
    height: 100%;

    background: rgba($dark, .1);

    .spinner-border {
      margin: add($rp-table-panel-padding-y, $border-width);

      width: $input-height;
      height: $input-height;

      color: $primary;
    }
  }

  .rp-table-heading-panel,
  .rp-table-actions-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: $rp-table-panel-padding-y $rp-table-panel-padding-x;

    background: $neutral-surface-var-alt;
  }

  .rp-table-heading-panel {
    .rp-table-title {
      display: flex;
      align-items: center;

      height: $input-height;

      h5 {
        margin: 0;
        line-height: 1;
      }

      .rp-table-title-text:not(:empty)::before,
      .rp-table-title-link::before {
        display: inline-block;
        content: '•';

        padding: 0 $rp-table-label-margin;
      }
    }

    .rp-table-pagination {
      display: flex;

      .rp-table-page-number,
      .rp-table-page-size {
        display: flex;
        align-items: center;
      }

      .rp-table-page-number {
        margin-right: $rp-table-label-margin * 3;

        label {
          margin: 0 $rp-table-label-margin 0 0;
        }

        input {
          width: $rp-table-input-width;
          text-align: center;
        }

        .rp-table-page-prev,
        .rp-table-page-next {
          padding: $rp-table-btn-padding-y * 2 $rp-table-btn-padding-y;

          &::after {
            content: '';
            display: inline-block;
            vertical-align: $caret-vertical-align;
          }

          &:disabled {
            cursor: default;
          }
        }

        .rp-table-page-prev {
          &::after {
            border-top: $caret-width solid transparent;
            border-right: $caret-width solid;
            border-bottom: $caret-width solid transparent;
          }

          &:disabled::after {
            border-right: $caret-width solid $neutral-surface-var-bg;
          }
        }

        .rp-table-page-next {
          &::after {
            border-top: $caret-width solid transparent;
            border-left: $caret-width solid;
            border-bottom: $caret-width solid transparent;
          }

          &:disabled::after {
            border-left: $caret-width solid $neutral-surface-var-bg;
          }
        }
      }

      .rp-table-page-size {
        label {
          display: flex;
          align-items: center;

          margin: 0 $rp-table-label-margin 0 0;
        }

        select {
          width: $rp-table-input-width;
        }

        .rp-table-page-size-warning {
          display: none;

          margin-right: $rp-table-label-margin * .5;
          color: $danger;
        }

        &.warn {
          .rp-table-page-size-warning {
            display: inline;
          }
        }
      }
    }
  }

  .rp-table-actions-panel {
    border-top: $border-width solid $border-color;

    .rp-table-actions {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      white-space: nowrap;

      & > .btn:not(:first-child),
      & > .rp-table-search:not(:first-child) {
        margin-left: $rp-table-btn-margin-x;
      }
    }

    .rp-table-actions-left {
      flex-grow: 1;
    }

    .rp-table-actions-menu:not(:empty) {
      margin-left: $rp-table-btn-margin-x;
    }

    .rp-table-search {
      width: $rp-table-search-width;
    }
  }

  .rp-table-scroll-container {
    position: relative;
    overflow: auto;
  }

  table {
    width: 100%;

    border-top: $border-width solid $border-color;

    border-collapse: collapse;
    border-spacing: 0;
  }

  .rp-column-header,
  .rp-cell {
    padding: $rp-table-cell-padding;
    border-left: $border-width solid $border-color;
    border-right: $border-width solid $border-color;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  &.rp-first-column-aligned {
    .rp-column-header,
    .rp-cell {
      &:first-child {
        padding-left: $rp-table-panel-padding-x;
      }
    }
  }

  .rp-column-header {
    background: $neutral-surface-var-alt;
    border-bottom: $border-width solid $border-color-alt;

    .rp-column-header-container {
      position: relative;

      .rp-column-resize-handle {
        position: absolute;
        top: 0;
        bottom: 0;

        width: add($border-width, $rp-table-cell-padding);
        right: subtract(-$rp-table-cell-padding * 1.5, $border-width);

        cursor: col-resize;
      }
    }

    &.rp-column-sortable {
      cursor: pointer;

      .rp-column-header-container {
        padding-right: $caret-width * 3;

        &::before,
        &::after {
          display: inline-block;
          content: '';

          position: absolute;
          right: 0;
        }

        &::before {
          top: subtract(subtract(50%, $caret-width), 1px);

          border-right: $caret-width solid transparent;
          border-bottom: $caret-width solid $neutral-surface-var-bg;
          border-left: $caret-width solid transparent;
        }

        &::after {
          bottom: subtract(subtract(50%, $caret-width), 1px);

          border-top: $caret-width solid $neutral-surface-var-bg;
          border-right: $caret-width solid transparent;
          border-left: $caret-width solid transparent;
        }
      }

      &.rp-column-sorted-asc {
        .rp-column-header-container::before {
          border-bottom: $caret-width solid;
        }
      }

      &.rp-column-sorted-desc {
        .rp-column-header-container::after {
          border-top: $caret-width solid;
        }
      }
    }
  }

  .rp-table-sticky-header {
    position: absolute;

    top: 0;
    z-index: 2;

    .rp-column-header {
      &.rp-column-sortable {
        cursor: default;

        .rp-column-header-container {
          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .rp-row {
    &:nth-child(even):not(.selected,.empty,.error) {
      background: $neutral-surface-var-alt;
    }

    &[data-interactive-element] {
      cursor: pointer;

      &:hover {
        .rp-cell {
          position: relative;

          &::before {
            content: '';
            pointer-events: none;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;

            border-top: $border-width solid $palette-blue-300;
            border-bottom: $border-width solid $palette-blue-300;
          }
        }
      }
    }

    &.active,
    &.selected {
      .rp-cell {
        background: $palette-blue-50;
      }
    }

    &.active {
      position: relative;
      z-index: 1;

      box-shadow: $elevation-600;
    }

    &.aggregated {
      .rp-cell {
        font-weight: $font-weight-bold;
      }
    }

    &.empty,
    &.error {
      .rp-cell {
        text-align: center;
      }
    }
  }

  .rp-row-select {
    width: 1%;
    padding: $rp-table-cell-padding $rp-table-cell-padding * 2;
    text-align: center;
  }

  .rp-actions {
    width: 1%;
    text-align: center;
    white-space: nowrap;

    &:not(.rp-column-header) {
      padding: 0;
    }

    .btn:not(.dropdown-item) {
      padding: $rp-table-action-padding;
    }

    .dropdown {
      display: inline-block;
    }
  }

  .rp-column-resize-bar {
    position: absolute;

    top: $border-width;
    bottom: 0;
    width: $border-width;

    opacity: .75;
    background: $dark;
  }
}
