.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: $zindex-fixed;

  display: flex;
  flex-direction: column;

  padding: 0;

  overflow: auto;
  @include hide-scrollbar();

  background-color: $neutral-surface;
  border-right: $border-width solid $border-color;

  @include media-breakpoint-up(md) {
    display: flex !important;

    width: $vue-sidebar-width !important;
  }

  &.collapsing {
    position: fixed;
    height: auto;
    width: 0;
    @include transition(width .3s ease);
  }
}
