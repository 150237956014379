:root {
  --rpvue-ActivityStreamEmailDraftRecipients-padding:                 var(--rpvue-spacer-3) 0;
  --rpvue-ActivityStreamEmailDraftRecipients-borderBottom:            var(--rpvue-borderWidth) solid var(--rpvue-colorBorder-alt);

  --rpvue-ActivityStreamEmailDraftRecipients-label-paddingRight:      var(--rpvue-spacer-6);
}

.rpvue-ActivityStreamEmailDraftRecipients {
  display: flex;
  align-items: center;

  padding: var(--rpvue-ActivityStreamEmailDraftRecipients-padding);
  border-bottom: var(--rpvue-ActivityStreamEmailDraftRecipients-borderBottom);
}

.rpvue-ActivityStreamEmailDraftRecipients-label {
  display: inline-flex;

  padding-right: var(--rpvue-ActivityStreamEmailDraftRecipients-label-paddingRight);
}
