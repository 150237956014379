:root {
  --rpvue-ActivityStreamEmailContent-padding: var(--rpvue-spacer-6);
  --rpvue-ActivityStreamEmailContent-wrapper-gap: var(--rpvue-spacer-4);
  --rpvue-ActivityStreamEmailContent-content-maxHeight: 10rem;
  --rpvue-ActivityStreamEmailContent-overlay-height: 5rem;
  --rpvue-ActivityStreamEmailContent-overlay-background: linear-gradient(180deg, #0000, #{$container-surface});
}

.rpvue-ActivityStreamEmailContent {
  padding: var(--rpvue-ActivityStreamEmailContent-padding);
}

.rpvue-ActivityStreamEmailContent-wrapper {
  display: flex;
  flex-direction: column;

  gap: var(--rpvue-ActivityStreamEmailContent-wrapper-gap);
}

.rpvue-ActivityStreamEmailContent-content {
  position: relative;
  overflow: clip;

  max-height: var(--rpvue-ActivityStreamEmailContent-content-maxHeight);

  &.is-expanded {
    max-height: none;
  }
}

.rpvue-ActivityStreamEmailContent-html {
  word-break: break-word;
}

.rpvue-ActivityStreamEmailContent-text {
  white-space: pre-wrap;
  word-break: break-word;
}

.rpvue-ActivityStreamEmailContent-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  height: var(--rpvue-ActivityStreamEmailContent-overlay-height);
  background: var(--rpvue-ActivityStreamEmailContent-overlay-background);
}

.rpvue-ActivityStreamEmailContent-sticky {
  position: relative;

  &.is-expanded {
    position: sticky;
    bottom: var(--rpvue-ActivityStreamEmailContent-padding);
  }
}

.rpvue-ActivityStreamEmailContent-toggle {
  position: absolute;
  right: 0;
  bottom: 0;
}
