$rpvue-UserMenuItem-padding:                   map-get($spacers, 2);
$rpvue-UserMenuItem-left-maxWidth:             13.125rem;
$rpvue-UserMenuItem-right-maxWidth:            3.375rem;
$rpvue-UserMenuItem-right-maxHeight:           2rem;
$rpvue-UserMenuItem-borderRadius:              $border-radius-small;
$rpvue-UserMenuItem-onHover-backgroundColor:   $palette-purple-100;



.rpvue-UserMenuItem {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: $rpvue-UserMenuItem-padding;
  border-radius: $rpvue-UserMenuItem-borderRadius;

  &:hover {
    background-color: $rpvue-UserMenuItem-onHover-backgroundColor;
  }
}

.rpvue-UserMenuItem-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  max-width: $rpvue-UserMenuItem-left-maxWidth;
}

.rpvue-UserMenuItem-right {
  display: flex;
  max-width: $rpvue-UserMenuItem-right-maxWidth;
  max-height: $rpvue-UserMenuItem-right-maxHeight;
}