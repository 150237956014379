$rpvue-UserMenuDetails-width:                          18.5rem;
$rpvue-UserMenuDetails-icon-fontSize:                  .875rem;
$rpvue-UserMenuDetails-gap:                            map-get($spacers, 2);
$rpvue-UserMenuDetails-padding:                        map-get($spacers, 2) 0;
$rpvue-UserMenuDetails-wrapper-padding:                0 map-get($spacers, 2);
$rpvue-UserMenuDetails-link-padding:                   map-get($spacers, 2);
$rpvue-UserMenuDetails-borderRadius:                   $border-radius-small;
$rpvue-UserMenuDetails-onHover-backgroundColor:        $palette-purple-100;
$rpvue-UserMenuDetails-logOut-onHover-color:           $color-error;
$rpvue-UserMenuDetails-logOut-onHover-backgroundColor: $color-error-bg;
$rpvue-UserMenuDetails-link-color:                     $palette-black-1000;



.rpvue-UserMenuDetails {
  display: flex;
  flex-direction: column;
  width: $rpvue-UserMenuDetails-width;
  padding: $rpvue-UserMenuDetails-padding;
  gap: $rpvue-UserMenuDetails-gap;
  border-radius: $rpvue-UserMenuDetails-borderRadius;
}

.rpvue-UserMenuDetails-wrapper {
  padding: $rpvue-UserMenuDetails-wrapper-padding;
}

.rpvue-UserMenuDetails-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $rpvue-UserMenuDetails-link-padding;
  color: $rpvue-UserMenuDetails-link-color;

  &:hover {
    color: $rpvue-UserMenuDetails-link-color;
    text-decoration: none;
  }
}

.rpvue-UserMenuDetails-developer {
  display: flex;
}

.rpvue-UserMenuDetails-activeProfileDetails {
  border-radius: $rpvue-UserMenuDetails-borderRadius;

  &:hover {
    background-color: $rpvue-UserMenuDetails-onHover-backgroundColor;
  }
}

.rpvue-UserMenuDetails-logOut {
  border-radius: $rpvue-UserMenuDetails-borderRadius;

  &:hover {
    color: $rpvue-UserMenuDetails-logOut-onHover-color;
    background-color: $rpvue-UserMenuDetails-logOut-onHover-backgroundColor;
  }
}

.rpvue-UserMenuDetails-divider {
  width: 100%;
  margin: 0;
}

.rpvue-UserMenuDetails-icon {
  font-size: $rpvue-UserMenuDetails-icon-fontSize;
}